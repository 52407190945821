<template>
    <div class="root-div">
        <v-app>
            <router-view></router-view>
        </v-app>
    </div>
</template>

<script>
export default {
  name: 'app'
}
</script>

