import TC_Wrapper from 'vue-tag-commander';
import { floaContractClient } from './helper';
import store from './store';

function isMobile() {
    return window.innerWidth <= 700;
}

function getQueryContext() {
    const splitContext = window.location.href.split("context=");
    let context = "";
    if (splitContext.length > 1) {
        context = splitContext[1];
    }

    return context;
}

function initTagCommander(context) {
    if (context === "") {
        return Promise.resolve(true);
    }

    const wrapper = TC_Wrapper.getInstance();

    floaContractClient.get('/tcVars', { params: { context } }).then((response) => {
        const tcVarsData = response.data;

        if (tcVarsData.tc_vars) {
            tcVarsData.tc_vars.env_support = isMobile() ? "mobile" : "web";
            store.commit('setTCVars', tcVarsData.tc_vars)

            const urlTagCommanderScript = tcVarsData.url_tc_script
            return wrapper.addContainer("container_body", urlTagCommanderScript, "head");
        }
    })
}

function setTagCommanderVars(tcVars) {
    const wrapper = TC_Wrapper.getInstance();

    if (tcVars) {
        wrapper.setTcVars(tcVars);
        wrapper.reloadAllContainers({});
    }
}

export { isMobile, getQueryContext, initTagCommander, setTagCommanderVars };