import Vue from 'vue';
import Vuex from 'vuex';
import { components, log } from './helper';

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        context: null,
        tcvars: null,
        sessionId: null,
        currentStep: components.loader,
        returnUrl: null,
        signbookResponse: null,
    },
    mutations: {
        setContext(state, context) {
            state.context = context;
            log(state.context, `The context is stored with value ${context}`);
        },
        setTCVars(state, tcvars) {
            state.tcvars = tcvars;
            log(state.context, `The TCVars are stored with value ${JSON.stringify(tcvars)}`);
        },
        setSessionId(state, sessionId) {
            state.sessionId = sessionId;
            log(state.context, `The TMX session Id is stored with value ${sessionId}.`);
        },
        setCurrentStep(state, step) {
            state.currentStep = step;
           log(state.context, `Current step is stored with value ${step}.`);

        },
        setReturnUrl(state, returnUrl) {
            state.returnUrl = returnUrl;
           log(state.context, `The return Url is stored with value ${returnUrl}`);
        },
        setSignbookResponse(state, contractUrl) {
            state.signbookResponse = contractUrl;
            log(state.context, `The signbook response is stored with value ${contractUrl}`)
        },
    },
    getters: {
        getContext: state => {
            return state.context;
        },
        getTCVars: state => {
            return state.tcvars;
        },
        getSessionId: state => {
            return state.sessionId;
        },
        getCurrentStep: state => {
            return state.currentStep;
        },
        getReturnUrl: state => {
            return state.returnUrl;
        },
        getSignbookResponse: state => {
            return state.signbookResponse;
        },
    },
    actions: {
        setContext({ commit }, context) {
            commit('setContext', context);
        },
        setTCVars({ commit }, context) {
            commit('setTCVars', context);
        },
        setSessionId({ commit }, sessionId) {
            commit('setSessionId', sessionId);
        },
        setCurrentStep({ commit }, step) {
            commit('setCurrentStep', step);
        },
        setReturnUrl({ commit }, returnUrl) {
            commit('setReturnUrl', returnUrl);
        },
        setSignbookResponse({ commit }, contractUrl) {
            commit('setSignbookResponse', contractUrl);
        },
    },
});

export default store;