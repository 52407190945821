import Vue from 'vue';
import App from './App';
import routes from './routes.js';
import VueRouter from 'vue-router';
import vuetify from './plugins/vuetify';
import store from './store';
import { getQueryContext, initTagCommander } from './tagCommanderHelper';

Vue.use(VueRouter);
Vue.use(vuetify);
Vue.config.productionTip = false;

const router = new VueRouter({ mode: 'history', routes });

Promise.all([
    initTagCommander(getQueryContext())
]).then(() => {
    new Vue({
        el: '#app',
        router,
        store,
        vuetify,
        render: h => h(App)
    }).$mount('#app');
});
