import Axios from 'axios';

let pendingRedirection = false;

const apiRoutes = {
    sessions: '/sessions',
    signbook: '/signbook',
    performedControls: '/controls/performedControls',
    controlsResult: '/controls/controlsResults',
    logs: '/logs',
    demands: '/demands',
    simulations: '/simulations'
}

const components = {
    loader: 'loader-step',
    sign: 'sign-step'
}

const floaContractClient = Axios.create({
    baseURL: `/api`,
    headers: { 'Content-Type': 'application/json' },
});

function redirectToReturnUrl(returnUrl, success) {
    if (!pendingRedirection) {
        pendingRedirection = true;

        if (returnUrl.includes('?')) {
           returnUrl = returnUrl + "&"
        }
        else {
           returnUrl = returnUrl + "?"
        }
        setTimeout(() => { window.location = returnUrl + "success=" + success; }, 2000);
    }
}

function log(context, message, isError = false) {
    floaContractClient.post(apiRoutes.logs, {
         context,
         message,
         isError
     });
 }

export { apiRoutes, components, floaContractClient, redirectToReturnUrl, log };
