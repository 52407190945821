<template>
    <div>
        <component :is="getCurrentStep"></component>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import { components } from './../helper';

    export default {
        name: 'Home',
        data() {
            return {
                sessionsError: false,
            };
        },
        components: {
            [components.loader]: () => import('./Loader.vue'),
            [components.sign]: () => import('./Sign.vue'),
        },
        methods: {
            ...mapActions([
                'setContext',
                'setCurrentStep',
                'setSessionId',
            ]),
        },
        computed: {
            ...mapGetters([
                'getContext',
                'getCurrentStep',
                'getSessionId',
            ]),
        },
        created() {
            const context = this.$route.query.context
            this.setContext(context);
            this.setCurrentStep(components.loader);
        }
    }
</script>
